import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import AOS from 'aos';

import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import 'fullpage.js/vendors/scrolloverflow'; // Optional. When using scrollOverflow:true
import 'fullpage.js/dist/fullpage.css';
import VueFullPage from 'vue-fullpage.js';
import VueScrollTo from 'vue-scrollto';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CoolLightBox from 'vue-cool-lightbox';
import './assets/style.scss';

Vue.use(IconsPlugin);

Vue.use(VueFullPage);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(CoolLightBox);
Vue.use(VueScrollTo);
Vue.use(VueMeta);

Vue.use(VueAnalytics, {
    id: 'UA-XXX-X'
});

new Vue({
    created () {
        AOS.init();
    },
    router,
    render: h => h(App)
}).$mount('#app');
