const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/Base');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');

export default {
    path: '/',
    name: 'DashboardLayout',
    component: Layout,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        }
    ]
};
